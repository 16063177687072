img {max-width: 100% ;} 

/* disable css animations */
.touch .animated {
	-webkit-animation-duration: 0 !important;
	animation-duration: 0 !important;
	-webkit-animation-delay: 0 !important;
	animation-delay: 0 !important;
} 

/* #Tablet (Landscape)
================================================== */

@media only screen and (max-width: 1024px) {

}

/* #Tablet (Portrait)
================================================== */
    
@media only screen and (max-width: 959px) {

}

/* #Mobile (Landscape)
================================================== */

@media only screen and (max-width: 767px) {

#container_hornav {position: static !important;}
.sidecol_a, .sidecol_b, .items-row .item {
	width:100% !important;
}
.backgrounds .sidecol_a, .backgrounds .sidecol_b {display:none;} 

#content_remainder {
	width: 100% !important;
}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2 {
	width:100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}

.maincontent img {
	margin: 0px 5px !important;
}
/* Slogan Adjust */
p.site-slogan {
    right: 0px;
}

}

/*  #Mobile (Portrait)
================================================== */

    /* Note: Design for a width of 320px */

@media only screen and (max-width: 440px) {
	
.wrapper960, .content_background, #header {margin: 0 auto;}

#content_remainder, .items-row .item {
    width: 100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2, .sidecol_a, .sidecol_b {
	width:100% !important;
}
.slidesjs-previous.slidesjs-navigation, .slidesjs-next.slidesjs-navigation {
margin-top: -25px;
}
#container_main .wrapper960 {
    margin-top: 0px;
}
#footermenu {
    float: left;
}


}


